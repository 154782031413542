// Chargement de la page et barre de progression

let percentage = 0;
const percentageText = document.getElementById('percentage');
const progressBarFill = document.getElementById('progress-fill');
const loadingScreen = document.getElementById('loading-screen');

// Mise à jour de la barre de progression
function updateProgress() {
    percentage++;
    percentageText.textContent = `${percentage}%`;
    progressBarFill.style.width = `${percentage}%`;

    if (percentage < 100) {
        setTimeout(updateProgress, 10); // Ajuste la vitesse ici pour plus de fluidité
    } else {
        // Disparaître l'écran de chargement en douceur
        setTimeout(() => {
            loadingScreen.style.opacity = '0';
            loadingScreen.style.visibility = 'hidden';
            document.getElementById('main-content').style.display = 'block';
        }, 800);
    }
}

// Démarrer l'animation au chargement de la page
window.addEventListener('load', updateProgress);


// Défilement fluide avec la molette de la souris

let scrollTimeout;

window.addEventListener('wheel', () => {
  if (scrollTimeout) clearTimeout(scrollTimeout);

  document.documentElement.style.scrollBehavior = 'auto'; // Désactiver le défilement fluide

  scrollTimeout = setTimeout(() => {
    document.documentElement.style.scrollBehavior = 'smooth'; // Réactiver le défilement fluide
  }, 100); // Réactive après 100ms d'arrêt
});


// Gestion des vidéos cliquables

const videos = document.querySelectorAll('video.grid-image');

videos.forEach(video => {
  video.addEventListener('click', () => {
    video.paused ? video.play() : video.pause();
  });
});

// FOOTER

// Mise à jour de l'heure
function updateTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    document.getElementById('time').textContent = `${hours}:${minutes}:${seconds}`;
}

setInterval(updateTime, 1000);
updateTime();

// Gestion du bouton de lecture personnalisé
const video = document.getElementById('myVideo');
const playButton = document.getElementById('playButton');

playButton.addEventListener('click', () => {
  video.paused ? video.play() : video.pause();
});

video.addEventListener('play', () => playButton.classList.add('hidden'));
video.addEventListener('pause', () => playButton.classList.remove('hidden'));
video.addEventListener('ended', () => playButton.classList.remove('hidden'));


// Chargement dynamique de contenu

document.getElementById("loadContentBtn").addEventListener("click", () => {
  const contentDiv = document.getElementById("dynamicContent");
  const newContent = document.createElement("p");
  newContent.textContent = "Voici du contenu chargé dynamiquement !";
  contentDiv.appendChild(newContent);
});

// Lazy loading des vidéos

document.addEventListener("DOMContentLoaded", () => {
  const lazyVideos = document.querySelectorAll("video");

  const videoObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const video = entry.target;
        const source = video.querySelector("source");

        source.src = source.dataset.src; // Charge la vidéo
        video.load();
        video.play(); // Joue la vidéo après le chargement

        observer.unobserve(video); // Arrêter d'observer la vidéo
      }
    });
  }, { threshold: 0.25 }); // La vidéo doit être visible à 25% pour déclencher l'événement

  lazyVideos.forEach(video => videoObserver.observe(video));
});

fetch('data.json')
  .then(response => response.json())
  .then(data => {
    console.log(data);
  })
  .catch(error => {
    console.error('Erreur de chargement:', error);
  });